// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-blogs-blog-1-index-tsx": () => import("./../../../src/pages/blogs/blog1/index.tsx" /* webpackChunkName: "component---src-pages-blogs-blog-1-index-tsx" */),
  "component---src-pages-blogs-blog-2-index-tsx": () => import("./../../../src/pages/blogs/blog2/index.tsx" /* webpackChunkName: "component---src-pages-blogs-blog-2-index-tsx" */),
  "component---src-pages-blogs-blog-wrapper-tsx": () => import("./../../../src/pages/blogs/BlogWrapper.tsx" /* webpackChunkName: "component---src-pages-blogs-blog-wrapper-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

